import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { message } from "antd";
import Register from "./Views/Auth/index";
import Sidebar from "./Components/Sidebar";
import PrivateRoute from "./Components/PrivateRoute";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Home from "./Views/Home";
import WhatsappSMS from "./Views/WhatsappSMS";
import Students from "./Views/Users";
import SettingWhatsaap from "./Views/SettingWhatsaap";
import Absence from "./Views/Absence";
import Latents from "./Views/Latents";
import Exam from "./Views/Exam";
import AbsenceReport from "./Views/AbsenceReport";
import ExamResults from "./Views/ExamResults";
import AdminSMS from "./Views/AdminSMS";
import BehavioralReport from "./Views/BehavioralReport";
import StudentPoints from "./Views/StudentPoints";
import QRReader from "./Views/QRReader";
import WaitingClass from "./Views/AdminSMS/WaitingClass";
import DailyWatch from "./Views/AdminSMS/DailyWatch";
import Slock from "./Views/Slock";

const AllRoutes = () => {
  const intl = useIntl();
  const { isOffline } = useSelector((state) => state.settings);

  useEffect(() => {
    if (isOffline) {
      message.error(intl.formatMessage({ id: "pleaseCheckInternet" }), 0);
    } else {
      message.destroy();
    }
  }, [isOffline]);

  return (
    <Routes>
      {/* <Route
        path='/'
        element={
          <PrivateRoute>
            <Sidebar component={<Home />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/absence'
        element={
          <PrivateRoute>
            <Sidebar component={<Absence />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/absence-report'
        element={
          <PrivateRoute>
            <Sidebar component={<AbsenceReport type='ABSENCE' />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/latents-report'
        element={
          <PrivateRoute>
            <Sidebar component={<AbsenceReport type='LATENTS' />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/latents'
        element={
          <PrivateRoute>
            <Sidebar component={<Latents />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/adminSMS'
        element={
          <PrivateRoute>
            <Sidebar component={<AdminSMS />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/whtsapp'
        element={
          <PrivateRoute>
            <Sidebar component={<WhatsappSMS />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/behavioral-report'
        element={
          <PrivateRoute>
            <Sidebar component={<BehavioralReport />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/students'
        element={
          <PrivateRoute>
            <Sidebar component={<Students />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/students-points'
        element={
          <PrivateRoute>
            <Sidebar component={<StudentPoints />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/qr'
        element={
          <PrivateRoute>
            <Sidebar component={<QRReader />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/exam'
        element={
          <PrivateRoute>
            <Sidebar component={<Exam />} />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        path='/nataej'
        element={
          <PrivateRoute>
            <Sidebar component={<Nataej />} />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        path='/setting'
        element={
          <PrivateRoute>
            <Sidebar component={<SettingWhatsaap />} />
          </PrivateRoute>
        }
      />

      <Route
        path='/waitingClass'
        element={
          <PrivateRoute>
            <Sidebar component={<WaitingClass />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/dailyWatch'
        element={
          <PrivateRoute>
            <Sidebar component={<DailyWatch />} />
          </PrivateRoute>
        }
      /> */}
      {/* <Route path='/results' element={<ExamResults />} /> */}
      <Route path='/*' element={<Slock />} />
      {/* <Route path='/edit-info' element={<ThirdGradInfo />} /> */}
      {/* <Route path='vv' element={<Report name="محمد خالد" />} /> */}
      {/* <Route path='*' element={<Register />} /> */}
    </Routes>
  );
};

export default AllRoutes;
